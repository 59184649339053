import { atom, DefaultValue, selector } from 'recoil';
import { IGeoLocationState } from '../hooks/useMapGeolocation';
import { IMapPosition } from '../helper/mapStateSaveLocalStorage';

export interface IMapHandler {
    position: IMapPosition;
    userPosition: IGeoLocationState;
    followGEOLocation: boolean;
    settingsApplied: boolean;
}

export const mapHandlerRecoil = atom<IMapHandler>({
    key: `map-handler-recoil`,
    default: {
        settingsApplied: false,
        position: {
            latitude: 0,
            longitude: 0,
            zoom: 14,
        },
        userPosition: {
            latitude: 0,
            longitude: 0,
            altitude: 0,
            accuracy: null,
            error: null,
        },
        followGEOLocation: false,
    },
});

export const positionSelector = selector<IMapPosition>({
    key: 'position-selector',
    get: ({ get }) => {
        return get(mapHandlerRecoil).position;
    },
    set: ({ set }, newValue) =>
        set(
            mapHandlerRecoil,
            newValue instanceof DefaultValue
                ? newValue
                : (currVal) => {
                      return { ...currVal, position: newValue };
                  }
        ),
});

export const followGEOLocationSelector = selector<boolean>({
    key: 'follow-geolocation-selector',
    get: ({ get }) => {
        return get(mapHandlerRecoil).followGEOLocation;
    },
    set: ({ set }, newValue) =>
        set(
            mapHandlerRecoil,
            newValue instanceof DefaultValue
                ? newValue
                : (currVal) => {
                      return { ...currVal, followGEOLocation: newValue };
                  }
        ),
});

export const userPositionSelector = selector<IGeoLocationState>({
    key: 'user-position-selector',
    get: ({ get }) => {
        return get(mapHandlerRecoil).userPosition;
    },
    set: ({ set }, newValue) =>
        set(
            mapHandlerRecoil,
            newValue instanceof DefaultValue
                ? newValue
                : (currVal) => {
                      return { ...currVal, userPosition: newValue };
                  }
        ),
});

export const settingsAppliedSelector = selector<boolean>({
    key: 'settings-applied-selector',
    get: ({ get }) => {
        return get(mapHandlerRecoil).settingsApplied;
    },
    set: ({ set }, newValue) =>
        set(
            mapHandlerRecoil,
            newValue instanceof DefaultValue
                ? newValue
                : (currVal) => {
                      return { ...currVal, settingsApplied: newValue };
                  }
        ),
});
