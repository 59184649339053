import React, { useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationCrosshairs } from '@fortawesome/pro-duotone-svg-icons';
import { useRecoilValue } from 'recoil';
import { followGEOLocationSelector, userPositionSelector } from '../../state/mapHandler.state';
import { useFlyToPosition } from '../../../MapBoxMap/hooks/useFlyToPosition';

export interface ICurrentPositionButton {}

export const CurrentPositionButton: React.FC<ICurrentPositionButton> = () => {
    const { latitude, longitude, accuracy } = useRecoilValue(userPositionSelector);

    const followMode = useRecoilValue(followGEOLocationSelector);

    const { flyTo } = useFlyToPosition();

    const setPosition = useCallback(() => {
        if (accuracy && latitude && longitude) {
            flyTo(latitude, longitude);
        }
    }, [accuracy, flyTo, latitude, longitude]);

    const opacity = useMemo(() => {
        if (!accuracy) return 'opacity-40';
        return accuracy <= 300 ? 'opacity-100' : 'opacity-40';
    }, [accuracy]);

    const modeStyles = useMemo(() => {
        return followMode ? 'bg-tertiary' : 'bg-primary-dark';
    }, [followMode]);

    const hasPositionData = useMemo(() => {
        if (accuracy) return true;

        return !!(latitude && longitude);
    }, [latitude, longitude, accuracy]);

    return (
        <button
            className={`relative flex items-center justify-center rounded-full p-2 transition-all duration-1000 ${modeStyles} ${opacity}`}
            data-test-id="current-position-button"
            data-test-follow-mode={followMode}
            data-test-has-position-data={hasPositionData}
            onClick={setPosition}
        >
            <FontAwesomeIcon icon={faLocationCrosshairs} className="h-4 text-secondary" />
        </button>
    );
};
