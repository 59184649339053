import React, { useCallback, useEffect, useRef } from 'react';
import {
    followGEOLocationSelector,
    positionSelector,
    userPositionSelector,
} from '../../MapHandler/state/mapHandler.state';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useFlyToPosition } from '../hooks/useFlyToPosition';
// eslint-disable-next-line import/no-extraneous-dependencies
import { debounce, DebouncedFunc } from 'lodash';

export interface IFollowModeExecutor {}

export const FollowModeExecutor: React.FC<IFollowModeExecutor> = () => {
    const followMode = useRecoilValue(followGEOLocationSelector);

    const userPosition = useRecoilValue(userPositionSelector);

    const setMapPosition = useSetRecoilState(positionSelector);

    const { flyTo } = useFlyToPosition();

    const startFlyTo = useCallback(() => {
        if (userPosition.latitude && userPosition.longitude) {
            // eslint-disable-next-line no-console
            console.log('Flying to user position', userPosition.latitude, userPosition.longitude);
            flyTo(userPosition.latitude, userPosition.longitude);

            setMapPosition({
                latitude: userPosition.latitude,
                longitude: userPosition.longitude,
            });
        }
    }, [flyTo, setMapPosition, userPosition.latitude, userPosition.longitude]);

    const debouncedFlyTo = useRef<DebouncedFunc<(lat: number, lng: number) => void>>(
        debounce(startFlyTo, 300)
    );

    useEffect(() => {
        if (followMode && userPosition.latitude && userPosition.longitude) {
            debouncedFlyTo.current(userPosition.latitude, userPosition.longitude);
        }
    }, [flyTo, followMode, userPosition.latitude, userPosition.longitude]);

    return <></>;
};
