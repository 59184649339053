import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Map, { NavigationControl } from 'react-map-gl';
import { MeMarkerMB } from './MeMarkerMB';
import { StoryRenderer } from './StoryRenderer';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { appStateRecoil, getMapType, getPlaceMode, getSelectedMoment } from '../../recoil/appState';
import { useStories } from '../../hooks/storage/useStories';
import { MomentsRenderer } from './MomentsRenderer';
import { StoryCloser } from '../Stories/StoryCloser';
import { FlyToStory } from './FlyToStory';
import { StoryConnectorRenderer } from './StoryConnectorRenderer';
import { useMoments } from '../../hooks/storage/useMoments';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import { IMoment } from '../../interfaces/Moment.interfaces';
import { MapTypeToggle } from './MapTypeToggle';
import { MomentDetailsFull } from './MomentDetailsFull';
import { userPositionSelector } from '../MapHandler/state/mapHandler.state';
import { CurrentPositionButton } from '../MapHandler/elements/currentPositionButton/CurrentPositionButton';
import { FollowModeExecutor } from './Elements/FollowModeExecutor';
import { mapStateSaveLocalStorage } from '../MapHandler/helper/mapStateSaveLocalStorage';

export interface IMapBoxMap {
    longitude: number;
    latitude: number;
    zoom?: number;
    fixPosition: boolean;
}

interface IMapBoxEvent {
    lngLat: {
        lng: number;
        lat: number;
    };
}

// TODO Refactor this component
// TODO Abstract mapbox into a MapWrapper component, and make leaflet a available as well

export const MapBoxMap: React.FC<IMapBoxMap> = (props) => {
    const { latitude, longitude, fixPosition, zoom } = props;

    const placeMode = useRecoilValue(getPlaceMode);

    const { currentStory } = useStories();

    const { createMoment } = useMoments();

    const mapType = useRecoilValue(getMapType);

    const selectedMoment = useRecoilValue(getSelectedMoment);

    const setAppState = useSetRecoilState(appStateRecoil);

    const mapBoxPK = process.env.NEXT_PUBLIC_MAPBOX_PK ?? null;

    const {
        latitude: geoLatitude,
        longitude: geoLongitude,
        accuracy,
    } = useRecoilValue(userPositionSelector);

    const [currentPosition, setCurrentPosition] = useState(false);

    const [latLng, setLatLng] = useState({
        latitude: latitude,
        longitude: longitude,
    });

    useEffect(() => {
        if (accuracy && !currentPosition && geoLatitude && geoLongitude && !fixPosition) {
            setCurrentPosition(true);
            setLatLng({
                latitude: geoLatitude,
                longitude: geoLongitude,
            });
        }
    }, [accuracy, currentPosition, geoLatitude, geoLongitude, latitude, longitude, fixPosition]);

    const holdTimeoutRef = useRef<NodeJS.Timeout | number | null>(null);

    const handleMouseDown = useCallback(
        (event: IMapBoxEvent) => {
            if (sessionStorage.getItem('isDragging') !== null) return undefined;

            setAppState((currVal) => {
                return { ...currVal, selectedMoment: null };
            });

            // Place a new marker; this should be toggled in MomentAddButton.tsx
            if (placeMode) {
                const momentId = nanoid();

                const order = currentStory?.moments.length ?? 0;

                const newMoment = {
                    id: momentId,
                    media: [],
                    longitude: event.lngLat.lng,
                    latitude: event.lngLat.lat,
                    label: 'Neuer Moment',
                    type: 'vista',
                    created: dayjs().toISOString(),
                    parentStory: currentStory?.id ?? null,
                    description: '',
                    order: order,
                } as IMoment;

                // TODO Refactor this into createMoment(moment) and set order correctly for stories
                void createMoment(newMoment);

                setAppState((currVal) => {
                    return { ...currVal, placeMode: false };
                });

                // Off the place mode
                setTimeout(() => {
                    setAppState((currVal) => {
                        return { ...currVal, selectedMoment: newMoment };
                    });
                }, 250);
            }

            holdTimeoutRef.current = setTimeout(() => {
                if (sessionStorage.getItem('isDragging') !== null) return undefined;

                // Trigger your hold action here
                // eslint-disable-next-line no-console,@typescript-eslint/no-unsafe-member-access
                console.log('Map was held.', event.lngLat);

                // TODO Removed because it was a bad UX
            }, 1000); // 1000ms (1s)
        },
        [createMoment, currentStory?.id, currentStory?.moments.length, placeMode, setAppState]
    );

    const handleMouseUp = () => {
        if (holdTimeoutRef.current !== null) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            clearTimeout(holdTimeoutRef.current);
        }
    };

    const mapStyle = useMemo((): string => {
        return mapType === 'satellite'
            ? 'mapbox://styles/mapbox/satellite-streets-v12'
            : 'mapbox://styles/mapbox/outdoors-v12';
    }, [mapType]);

    return (
        <>
            {mapBoxPK && (
                <Map
                    id="mapbox-map"
                    mapboxAccessToken={mapBoxPK}
                    projection={{
                        name: 'globe',
                    }}
                    initialViewState={{
                        latitude: latitude ?? latLng.latitude,
                        longitude: longitude ?? latLng.longitude,
                        zoom: zoom ?? 14,
                    }}
                    style={{ width: '100%', height: '100%' }}
                    // mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
                    // mapStyle="mapbox://styles/mapbox/outdoors-v12"
                    mapStyle={mapStyle}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onTouchStart={handleMouseDown}
                    onTouchEnd={handleMouseUp}
                    onZoomEnd={(e) => {
                        /**
                         * Save the current position to local storage
                         */
                        mapStateSaveLocalStorage({
                            latitude: e.viewState.latitude,
                            longitude: e.viewState.longitude,
                            zoom: e.viewState.zoom,
                        });
                    }}
                    onDragEnd={(e) => {
                        /**
                         * Save the current position to local storage
                         */
                        mapStateSaveLocalStorage({
                            latitude: e.viewState.latitude,
                            longitude: e.viewState.longitude,
                            zoom: e.viewState.zoom,
                        });
                    }}
                >
                    <FollowModeExecutor />
                    <MeMarkerMB />
                    <NavigationControl />
                    {selectedMoment === null && (
                        <div className="fixed bottom-20 left-3 z-[1500]">
                            <CurrentPositionButton />
                        </div>
                    )}
                    {currentStory && <StoryRenderer story={currentStory} />}
                    <MomentsRenderer />
                    <FlyToStory />
                    <StoryConnectorRenderer />
                    <MapTypeToggle />
                </Map>
            )}

            <MomentDetailsFull />
            <StoryCloser />
        </>
    );
};
