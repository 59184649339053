import React from 'react';
import { EMapHandlerModes } from './enums/mapHandler.enums';
import { MapBoxMap } from '../MapBoxMap/MapBoxMap';
import { useRecoilValue } from 'recoil';
import { positionSelector, settingsAppliedSelector } from './state/mapHandler.state';
import { IMapHandlerSettings } from './interfaces/MapHandler.interfaces';
import { useMapHandlerApplySettings } from './hooks/useMapHandlerApplySettings';
import useMapGeoLocation from './hooks/useMapGeolocation';
import { MapDebugPanel } from './elements/mapDebugPanel/MapDebugPanel';

export interface IMapHandler {
    mode: EMapHandlerModes;
    settings?: IMapHandlerSettings;
    testMode?: boolean;
}

export const MapHandler: React.FC<IMapHandler> = (props) => {
    const { mode, settings } = props;

    // Watches Geo-Sensors and updates the map state
    useMapGeoLocation();

    // Applies initially the settings to the map given by the wrapping element
    useMapHandlerApplySettings(settings);

    const position = useRecoilValue(positionSelector);

    const settingsApplied = useRecoilValue(settingsAppliedSelector);

    return (
        <>
            <MapDebugPanel {...props} />

            {mode === EMapHandlerModes.MAPBOX && settingsApplied && (
                <MapBoxMap
                    fixPosition={false}
                    latitude={position.latitude}
                    longitude={position.longitude}
                    zoom={position.zoom ?? undefined}
                />
            )}
        </>
    );
};
