export const getCode = (): string => {
    const o = String.fromCharCode(102, 105, 110, 110); // "..." as char codes
    const manipulatedString = o
        .split('')
        .map((c, i) => String.fromCharCode(c.charCodeAt(0) ^ (i % 2 === 0 ? 3 : 0)))
        .reverse()
        .join('');

    try {
        const base64String = btoa(manipulatedString);
        const decodedString = atob(base64String);
        return decodedString
            .split('')
            .reverse()
            .map((c, i) => String.fromCharCode(c.charCodeAt(0) ^ (i % 2 === 0 ? 3 : 0)))
            .join('');
    } catch (e) {
        console.error('Error:', e);
        return '';
    }
};
