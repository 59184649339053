import { useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { getUnlocked } from '../recoil/appState';
import { getCode } from '../components/SplashScreen/helper/getCode';

export const useBetaCode = () => {
    const [unlocked, setUnlocked] = useRecoilState(getUnlocked);

    const checkCode = useCallback(
        (value: string) => {
            if (getCode().toLowerCase() === value.toLowerCase()) {
                localStorage.setItem('ul', '1');
                setUnlocked(true);
            }
        },
        [setUnlocked]
    );

    useEffect(() => {
        if (localStorage.getItem('ul') === '1') {
            setUnlocked(true);
        }
    }, [setUnlocked]);

    return { unlocked, setUnlocked, checkCode };
};
