import React, { useEffect, useMemo, useState } from 'react';
import { Marker } from 'react-map-gl';
import { ILngLat } from './interfaces/ILngLat';
import { useDeviceDirection } from '../../hooks/useDeviceDirection';
import { useFlyToPosition } from './hooks/useFlyToPosition';
import { useRecoilValue } from 'recoil';
import {
    followGEOLocationSelector,
    userPositionSelector,
} from '../MapHandler/state/mapHandler.state';

export const MeMarkerMB: React.FC = () => {
    const userPosition = useRecoilValue(userPositionSelector);

    const [mePos, setMePos] = useState<ILngLat | null>(null);

    const followGeoLocation = useRecoilValue(followGEOLocationSelector);

    const { flyTo } = useFlyToPosition();

    const [markerInit, setMarkerInit] = useState(false);

    const { rotation, hasDeviceOrientation } = useDeviceDirection();

    useEffect(() => {
        if (userPosition.accuracy && userPosition.latitude && userPosition.longitude) {
            setMePos({
                latitude: userPosition.latitude,
                longitude: userPosition.longitude,
            });
        }
    }, [userPosition]);

    useEffect(() => {
        // Set initial Marker setting for the first time
        if (markerInit) return undefined;

        if (userPosition.accuracy && userPosition.latitude && userPosition.longitude) {
            setMarkerInit(true);

            if (followGeoLocation) {
                flyTo(userPosition.latitude, userPosition.longitude, { zoom: 18 });
            }
        }
    }, [
        flyTo,
        followGeoLocation,
        userPosition.accuracy,
        userPosition.latitude,
        userPosition.longitude,
        markerInit,
    ]);

    const triangleStyle: React.CSSProperties = {
        transform: `rotate(-${rotation + 90}deg) translate(16px)`,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transformOrigin: 'center',
        width: '12px', // Adjust based on the size of the triangle
        height: '12px', // Adjust based on the size of the triangle
        marginLeft: '-6px', // Half of width
        marginTop: '-6px', // Half of height
    };

    const color = useMemo(() => {
        if (!userPosition.accuracy) return 'bg-neutral-300';
        if (userPosition.accuracy > 150) return 'bg-red-500';
        if (userPosition.accuracy <= 150 && userPosition.accuracy > 50) return 'bg-orange-500';
        return 'bg-blue-500';
    }, [userPosition.accuracy]);

    return (
        <>
            {mePos && (
                <Marker longitude={mePos.longitude} latitude={mePos.latitude}>
                    <figure
                        className={`relative aspect-[1/1] h-4 rounded-full ${color} pointer-events-none ring-1 ring-blue-300 ring-offset-2 ring-offset-white`}
                    >
                        <div className="pointer-events-none aspect-[1/1] h-4 animate-ping rounded-full bg-blue-700 duration-1000" />

                        {/* This is the Triangle */}
                        {hasDeviceOrientation && (
                            <div style={triangleStyle} className="pointer-events-none">
                                <img
                                    className="pointer-events-none rotate-[91deg] transform"
                                    src="/assets/icons/geoLocations/noun-triangle-6011603-12B0FB.svg"
                                    alt="pointer"
                                />
                            </div>
                        )}
                    </figure>
                </Marker>
            )}
        </>
    );
};
