import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { appStateRecoil, getUnlocked } from '../../recoil/appState';
import { useBetaCode } from '../../hooks/useBetaCode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket } from '@fortawesome/pro-duotone-svg-icons';

interface ISplashScreen {}

export const SplashScreen: React.FC<ISplashScreen> = () => {
    const unlocked = useRecoilValue(getUnlocked);

    const setAppState = useSetRecoilState(appStateRecoil);

    const [code, setCode] = useState('');

    const { checkCode } = useBetaCode();

    const startJourney = useCallback(() => {
        sessionStorage.setItem('headerCollapsed', 'true');

        setAppState((currVal) => {
            return { ...currVal, headerCollapsed: true };
        });
    }, [setAppState]);

    useEffect(() => {
        if (sessionStorage.getItem('headerCollapsed') === 'true') {
            setAppState((currVal) => {
                return { ...currVal, headerCollapsed: true };
            });
        }
    }, [setAppState]);

    return (
        <>
            <div className="fixed inset-0 z-50 h-screen w-screen">
                <img
                    src="/assets/splashscreen/Storyfiy.gif"
                    alt=""
                    className="relative h-full w-full object-cover"
                />

                <div className="absolute bottom-0 left-0 flex h-56 w-screen flex-col items-center justify-center">
                    {unlocked && (
                        <button
                            className={`bg-secondary px-8 py-4 text-xl font-medium text-primary-dark ring-offset-4 ring-offset-primary hover:ring-secondary focus:outline-0 focus:ring `}
                            onClick={() => {
                                setTimeout(() => {
                                    startJourney();
                                }, 450);
                            }}
                        >
                            <FontAwesomeIcon icon={faRocket} className="relative top-1 h-6 pr-2" />
                            Start your Story
                        </button>
                    )}
                    {!unlocked && (
                        <div className="relative -top-10 flex w-full flex-col gap-1">
                            <input
                                className="relative mx-auto w-[200px] bg-secondary px-2 py-2 text-center text-2xl font-medium text-neutral-600 placeholder:text-lg"
                                placeholder="Enter Beta-Code"
                                type="password"
                                alt="beta-code"
                                value={code}
                                onChange={(e) => {
                                    setCode(e.target.value);

                                    checkCode(e.target.value);
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
