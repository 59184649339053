import { Transition } from '@headlessui/react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { appStateRecoil, getSelectedMoment, getStorageUpdate } from '../../recoil/appState';

// Import Swiper styles
import 'swiper/css';
import ShareRow from '../ShareRow/ShareRow';
import { AddMediaBox } from './AddMediaBox';
import { ModalPopUp } from '../Modals/ModalPopUp';
import { AddMediaButton } from './AddMediaButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDoubleDown } from '@fortawesome/pro-duotone-svg-icons';
import { MediaSwiper } from '../MediaSwiper/MediaSwiper';

export interface IMomentDetailsFull {}

export const MomentDetailsFull: React.FC<IMomentDetailsFull> = () => {
    const setAppState = useSetRecoilState(appStateRecoil);
    const moment = useRecoilValue(getSelectedMoment);

    const scrollRef = useRef<HTMLDivElement>(null);

    const storageUpdate = useRecoilValue(getStorageUpdate);

    const [showContent, setShowContent] = useState(false);

    const mediaList = useMemo(() => {
        return moment?.media.slice().reverse();
    }, [moment?.media]);

    const close = useCallback(() => {
        setAppState((currVal) => {
            return { ...currVal, selectedMoment: null };
        });

        setShowContent(false);
    }, [setAppState]);

    useEffect(() => {
        setShowContent(false);

        setTimeout(() => {
            setShowContent(true);
        }, 250);

        return () => {
            setShowContent(false);
        };
    }, [moment]);

    const media = useMemo(() => {
        if (storageUpdate) {
            return moment?.media ?? [];
        }
    }, [moment?.media, storageUpdate]);

    const scrollToContent = useCallback(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'end',
            });
        }
    }, []);

    const [scrollIsDone, setScrollIsDone] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setScrollIsDone(entry?.isIntersecting ?? false);
            },
            // Consider as visible if at least 50% of the element's area is within the viewport
            { threshold: 1.0 }
        );

        if (scrollRef.current) {
            observer.observe(scrollRef.current);
        }

        // Clean up function
        return () => {
            if (scrollRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                observer.unobserve(scrollRef.current);
            }
        };
    }, [setScrollIsDone]);

    const hasMedia = useMemo(() => {
        if (!media) return false;
        return media.length > 0;
    }, [media]);

    return (
        <ModalPopUp show={moment !== null} closeAction={close} previewModeDefault={false}>
            {showContent && moment && (
                <Transition
                    show={showContent}
                    appear={showContent}
                    as={'div'}
                    enter="transition-all ease-in-out duration-200"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 "
                    leaveTo="opacity-0"
                >
                    <section className="relative flex w-full flex-row flex-nowrap gap-x-10">
                        {hasMedia && <MediaSwiper mediaList={mediaList} moment={moment} />}
                        {!hasMedia && (
                            <div className="relative mx-auto mt-10 flex w-full max-w-[300px] px-2">
                                <AddMediaBox moment={moment} />
                            </div>
                        )}
                        {/* Lower Button Bar */}
                        <div
                            className="absolute bottom-12 left-0 z-50 flex w-full flex-row items-center justify-center"
                            id="lowerButtonBar"
                        >
                            {/* TODO Hide, when scrollref is already scrolled  */}
                            {hasMedia && !scrollIsDone && (
                                <button
                                    className="relative flex h-max w-max"
                                    onClick={scrollToContent}
                                >
                                    <FontAwesomeIcon
                                        icon={faChevronDoubleDown}
                                        className="relative top-[1px] h-6 animate-pulse text-white/90"
                                    />
                                </button>
                            )}
                        </div>
                    </section>
                </Transition>
            )}
            <section className="relative z-0 mt-1 flex w-full flex-row items-center justify-center gap-1 px-2 py-2 pb-4">
                {media && media.length > 0 && <AddMediaButton />}
                {media && media.length === 0 && <div className="block h-8" />}
            </section>
            <section
                ref={scrollRef}
                className="relative z-0 mt-1 flex w-full flex-row items-center justify-center gap-1 px-2"
            >
                <ShareRow />
            </section>
        </ModalPopUp>
    );
};
