import React, { useCallback } from 'react';
import ExportedImage from 'next-image-export-optimizer';
import { GeoState } from '../GeoState/GeoState';
import { WakeLockButton } from '../WakeLockButton/WakeLockButton';
import { getHeaderCollapsed } from '../../recoil/appState';
import { useSetRecoilState } from 'recoil';

export interface IHeader {}

export const Header: React.FC<IHeader> = () => {
    const setHeaderCollapsed = useSetRecoilState(getHeaderCollapsed);

    const collapse = useCallback(() => {
        setHeaderCollapsed(false);
        sessionStorage.removeItem('headerCollapsed');
    }, [setHeaderCollapsed]);

    return (
        <nav className="relative left-0 top-0 z-30 h-16 w-screen border-b-2 border-primary">
            <ExportedImage
                className="h-full object-cover "
                src="/assets/splashscreen/headerbg.jpg"
                alt="header-image"
                width={1024}
                height={400}
            />

            <div className="absolute left-0 top-0 flex h-full w-full flex-row items-center justify-end gap-2 self-end pr-4">
                <button onClick={collapse}>
                    <ExportedImage
                        className="absolute left-4 top-2 h-16 w-16 rounded-full bg-primary p-0.5"
                        src="/assets/logos/Storyfiy-Logo.png"
                        alt="Logo"
                        width={100}
                        height={100}
                    />
                </button>

                <GeoState />
                <WakeLockButton />
            </div>
        </nav>
    );
};
