import React from 'react';
import { useRecoilValue } from 'recoil';
import { positionSelector, userPositionSelector } from '../../state/mapHandler.state';
import { IMapHandler } from '../../MapHandler';

export interface IMapDebugPanel extends IMapHandler {}

export const MapDebugPanel: React.FC<IMapDebugPanel> = (props) => {
    const { mode, testMode } = props;

    const position = useRecoilValue(positionSelector);
    const userPosition = useRecoilValue(userPositionSelector);

    return (
        <>
            <div
                data-test-map-mode={mode}
                data-test-position-lat={position.latitude}
                data-test-position-lng={position.longitude}
                data-test-me-position-lat={userPosition.latitude}
                data-test-me-position-lng={userPosition.longitude}
                className={testMode ? 'grid w-full grid-cols-2 font-mono text-xs' : 'hidden'}
            >
                <span>MapMode</span>
                <span data-test-id="debug-map-mode">{mode}</span>
                <hr className="col-span-2" />
                <span>Position lat</span>
                <span data-test-id="debug-map-position-latitude">{position.latitude}</span>
                <span>Position lng</span>
                <span data-test-id="debug-map-position-longitude">{position.longitude}</span>
                <hr className="col-span-2" />
                <span>MePosition lat</span>
                <span data-test-id="debug-map-userposition-latitude">{userPosition.latitude}</span>
                <span>MePosition lng</span>
                <span data-test-id="debug-map-userposition-longitude">
                    {userPosition.longitude}
                </span>
            </div>
        </>
    );
};
