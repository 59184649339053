export interface IMapPosition {
    latitude: number;
    longitude: number;
    zoom?: number;
}
export const mapStateSaveLocalStorage = (settings: IMapPosition) => {
    const mapStateSave: IMapPosition = {
        latitude: settings.latitude,
        longitude: settings.longitude,
        zoom: settings.zoom,
    };

    localStorage.setItem('lastPosition', JSON.stringify(mapStateSave));
};
