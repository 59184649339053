import { useCallback, useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { userPositionSelector } from '../state/mapHandler.state';

export interface IGeoLocationState {
    latitude: number | null;
    longitude: number | null;
    altitude?: number | null;
    accuracy: number | null;
    error: string | null;
}

const useMapGeoLocation = (): IGeoLocationState => {
    const [userPosition, setUserPosition] = useRecoilState(userPositionSelector);

    const lastPosition = useRef<null | GeolocationPosition>(null);

    const handleSuccess = useCallback(
        (position: GeolocationPosition) => {
            const hash = `${position.coords.latitude}+${position.coords.longitude}`;
            const hashLast = `${lastPosition.current?.coords.latitude ?? 0}+${
                lastPosition.current?.coords.longitude ?? 0
            }`;

            if (hash === hashLast) {
                return;
            }

            setUserPosition({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                altitude: position.coords.altitude,
                accuracy: position.coords.accuracy,
                error: null,
            });

            lastPosition.current = position;
        },
        [setUserPosition]
    );

    const handleError = useCallback(
        (error: GeolocationPositionError) => {
            setUserPosition({
                latitude: null,
                longitude: null,
                altitude: null,
                accuracy: null,
                error: error.message,
            });
        },
        [setUserPosition]
    );

    // https://w3c.github.io/geolocation-api/#dom-positionoptions-enablehighaccuracy
    useEffect(() => {
        if (navigator.geolocation) {
            const watcher = navigator.geolocation.watchPosition(handleSuccess, handleError, {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 5000,
            });

            // // eslint-disable-next-line no-console
            // console.log('WATCHER update', watcher);

            return () => {
                // // eslint-disable-next-line no-console
                // console.log('CLEAR WATCHER');
                navigator.geolocation.clearWatch(watcher);
            };
        } else {
            setUserPosition((state) => ({
                ...state,
                error: 'Geolocation is not supported by this browser',
            }));
        }
    }, [handleError, handleSuccess, setUserPosition]);

    return userPosition;
};

export default useMapGeoLocation;
