import type { NextPage } from 'next';
import React, { useCallback } from 'react';
import Head from 'next/head';
import { Scene } from '../src/components/Scene/Scene';
import { useRecoilValue } from 'recoil';
import { getHeaderCollapsed, getSelectedMoment } from '../src/recoil/appState';
import { useBetaCode } from '../src/hooks/useBetaCode';
import { MapHandler } from '../src/components/MapHandler/MapHandler';
import { EMapHandlerModes } from '../src/components/MapHandler/enums/mapHandler.enums';
import { AppBar } from '../src/components/AppBar/AppBar';
import { SplashScreen } from '../src/components/SplashScreen/SplashScreen';
import { Header } from '../src/components/Header/Header';
import { useRouter } from 'next/router';
import ClientOnly from '../src/components/ClientOnly/ClientOnly';
import { IMapPosition } from '../src/components/MapHandler/helper/mapStateSaveLocalStorage';

const Index: NextPage = () => {
    const selectedMoment = useRecoilValue(getSelectedMoment);
    const { unlocked } = useBetaCode();

    const headerCollapsed = useRecoilValue(getHeaderCollapsed);

    const { query, isReady } = useRouter();

    const initialPosition = useCallback((): IMapPosition | undefined => {
        /**
         * If the user has a query parameter sl, we will use this position
         */
        // v?sl=48.512417,7.353399
        if (query.sl) {
            const [latitude, longitude] = query.sl.toString().split(',');
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return { latitude: parseFloat(latitude!), longitude: parseFloat(longitude!) };
        }

        /**
         * If the user has been on the map before, we will use the last position
         */
        if (window && window.localStorage) {
            const lastPositionString = localStorage.getItem('lastPosition');
            if (lastPositionString) {
                const lastPosition = JSON.parse(lastPositionString) as IMapPosition;
                return {
                    latitude: lastPosition.latitude,
                    longitude: lastPosition.longitude,
                    zoom: lastPosition.zoom,
                };
            }
        }

        return undefined;
    }, [query.sl]);

    return (
        <div className="relative">
            <Head>
                <title>Storyfiy</title>
            </Head>

            {!headerCollapsed && <SplashScreen />}

            {/*{headerCollapsed && <HeroHeader />}*/}
            {headerCollapsed && <Header />}

            <Scene>
                <div className="relative z-0 flex h-[calc(100svh-50px)] w-full" key="mpwrapper">
                    {unlocked && isReady && (
                        <ClientOnly>
                            <MapHandler
                                mode={EMapHandlerModes.MAPBOX}
                                settings={{ followGEOLocation: false, position: initialPosition() }}
                            />
                        </ClientOnly>
                    )}
                </div>
                {selectedMoment === null && (
                    <div className="fixed bottom-0 w-full">
                        <AppBar />
                    </div>
                )}
            </Scene>
        </div>
    );
};
export default Index;
