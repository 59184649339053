import { IMapHandlerSettings } from '../interfaces/MapHandler.interfaces';
import { useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { mapHandlerRecoil } from '../state/mapHandler.state';

export const useMapHandlerApplySettings = (settings?: IMapHandlerSettings) => {
    const wasApplied = useRef<boolean>(false);

    const setMapHandlerState = useSetRecoilState(mapHandlerRecoil);

    useEffect(() => {
        if (settings && !wasApplied.current) {
            setMapHandlerState((prev) => {
                const newSettings = { ...prev };

                if (settings.followGEOLocation !== undefined) {
                    newSettings.followGEOLocation = settings.followGEOLocation;
                }

                if (settings.position !== undefined) {
                    newSettings.position = settings.position;
                }

                return {
                    ...prev,
                    ...newSettings,
                    settingsApplied: true,
                };
            });
            wasApplied.current = true;
        }
    }, [setMapHandlerState, settings]);
};
