import React, { useCallback, useRef } from 'react';
import { Autoplay, FreeMode, Pagination } from 'swiper';
import { Swiper, SwiperProps, SwiperRef, SwiperSlide } from 'swiper/react';
import { MediaImage } from '../MediaImage';
import { IMedia } from '../../interfaces/Media.interfaces';
import { useMediaAsset } from '../../hooks/useMediaAsset';
import { IMoment } from '../../interfaces/Moment.interfaces';

export interface IMediaSwiper {
    mediaList: IMedia[] | undefined;
    moment: IMoment;
}

export const MediaSwiper: React.FC<IMediaSwiper> = (props) => {
    const { mediaList, moment } = props;

    const { moveMediaToTrash } = useMediaAsset();

    const swiperRef =
        useRef<
            React.MutableRefObject<
                | SwiperRef
                | React.FunctionComponent<React.RefAttributes<SwiperRef> & SwiperProps>
                | null
            >
        >(null); // Create a ref to hold the swiper instance

    const getMediaByIndex = useCallback(
        (index: number) => {
            if (!mediaList) return null;

            return mediaList[index];
        },
        [mediaList]
    );

    return (
        <Swiper
            ref={swiperRef as never}
            className="relative flex h-full w-full flex-row bg-black"
            modules={[FreeMode, Pagination, Autoplay]}
            spaceBetween={10}
            loop={false}
            centeredSlidesBounds={false}
            slidesPerView={1}
            freeMode={false}
            onDoubleClick={(swiper) => {
                const mediaData = getMediaByIndex(swiper.activeIndex);

                // TODO Open Context Menu
                void moveMediaToTrash(moment, mediaData?.image.mediaId ?? '');
            }}
            pagination={{
                clickable: true,
            }}
            onSwiper={(swiper) => {
                swiper.wrapperEl.addEventListener('mousedown', (e) => {
                    if (swiper.autoplay) {
                        swiper.autoplay.stop();
                    }
                    e.preventDefault();
                });
                swiper.wrapperEl.addEventListener('touchstart', (e) => {
                    if (swiper.autoplay) {
                        swiper.autoplay.stop();
                    }
                    e.preventDefault();
                });
                swiper.wrapperEl.addEventListener('mouseup', (e) => {
                    if (swiper.autoplay) {
                        swiper.autoplay.start();
                    }
                    e.preventDefault();
                });
                swiper.wrapperEl.addEventListener('touchend', (e) => {
                    if (swiper.autoplay) {
                        swiper.autoplay.start();
                    }
                    e.preventDefault();
                });
            }}
            autoplay={{
                delay: 5000, // 5 seconds
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            }}
        >
            {mediaList &&
                mediaList.map((mediaItem, index) => {
                    return (
                        <SwiperSlide
                            key={`${mediaItem.image.mediaId ?? index} `}
                            id={`slide-${mediaItem.image.mediaId ?? index}`}
                        >
                            <div className="h-[calc(100svh-60px)] w-screen">
                                <MediaImage media={mediaItem} />
                            </div>
                        </SwiperSlide>
                    );
                })}
        </Swiper>
    );
};
